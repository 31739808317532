import React from "react";
import './Services.css'
import fire from '../Assets/fire.png'
import expose from '../Assets/expose.png'
import Arrowline from "../Assets/Vector 1.png"

function Services() {
    return (
        <div id="service" >
            <span className="services-stroke"> OUR SERVICES</span>
            <hr className="bigline"/>
            <hr className="smallline"/>
            <h1 className="services-head"> OUR SERVICES</h1>
            <img src={Arrowline} className="services-underline"/>
        <div className="container-2" >
            <div className="col-1">
                <h2 className="service-title">What is medical waste?</h2>
                <p className="service-sub">Medical waste management refers to the process of handling, treating, and disposing of waste generated by healthcare facilities such as hospitals, clinics, laboratories, and medical research facilities. This waste, often referred to as medical or healthcare waste, can include materials such as used needles, syringes, bandages, swabs, cultures, bodily fluids, pharmaceuticals, and other potentially infectious or hazardous materials.</p>
                <ul className="service-list">
                    <li>Medical Waste Collection:
                        <p>The company offers regular and reliable collection services tailored to the needs of healthcare facilities. This includes scheduled pickups of various types of medical waste such as sharps, infectious waste, pathological waste, and pharmaceutical waste.</p>
                    </li>
                    <li>Transportation
                        <p>They ensure safe transportation of collected medical waste from the client's premises to the treatment and disposal facility. This involves compliant packaging, labeling, and manifesting according to regulatory requirements.</p>
                    </li>
                    <li>Treatment and Disposal
                        <p>The company employs advanced technologies and processes for the treatment and disposal of medical waste. This may include methods like autoclaving, incineration, chemical treatment, or alternative treatment methods to render the waste non-hazardous before disposal.</p>
                    </li>
                    <li>Compliance Assistance   
                        <p>They provide guidance and support to clients to ensure compliance with federal, state, and local regulations governing medical waste management. This includes assistance with documentation, training, and audits.</p>
                    </li>
                    <li>Environmental Responsibility:
                        <p>The company offers consultation services to help healthcare facilities optimize their waste management practices, minimize waste generation, and maximize safety. They also conduct training sessions for staff on proper waste segregation, handling, and disposal procedures.</p>
                    </li>
                    <li>Data Security and Confidentiality:
                        <p>Companies are expected to prioritize environmental sustainability in their operations by minimizing waste generation, promoting recycling and energy recovery, and employing eco-friendly disposal methods whenever possible. They should strive to reduce the environmental impact of their activities and comply with environmental regulations.</p>
                    </li>
                    <li>Customer Satisfaction:
                        <p>Companies must provide reliable and efficient services that meet the needs and expectations of their clients. This includes prompt and courteous customer support, transparent communication, and flexibility in scheduling pickups and deliveries.</p>
                    </li>
                    <li>Financial Transparency:
                        <p>Clients should receive clear and transparent pricing information for the services provided, including any additional fees or charges. Companies should ensure fairness and integrity in their billing practices and provide detailed invoices or statements for client records and auditing purposes.</p>
                    </li>
                </ul>
            </div>
            <div className="col-2">
                    <div className="z-1"><img src={fire} className="imgs-1"/></div>
                    <div className="z-2"><img src={expose} className="imgs-2"/></div>
            </div>
            
        </div>
        </div>
    )

}
export default Services